import '@babel/polyfill';
import React, {Fragment} from 'react'
import PropTypes from 'prop-types'
import Router from 'next/router';
import Block from '../adapters/helpers/Block';
import contentfulClient from '../adapters/model/ContentfulClient';
import dynamic from 'next/dynamic';
const GTMScriptFunction = dynamic(() => import('../components/GTMScriptFunction'));
const ArticlePageSEO = dynamic(() => import('../components/SEOschema/ArticlePageSEO'));
const FaqSectionSEO = dynamic(() => import('../components/SEOschema/FaqSectionSEO'));
const HowToSEO = dynamic(() => import('../components/SEOschema/HowToSEO'));
const Meta = dynamic(() => import('../components/Meta/Meta'));

class Article extends React.Component {

    componentDidMount() {
        Router.beforePopState(({as}) => {
            location.href = as;
        });
    }

    static async getInitialProps({ asPath }) {
        const client = await contentfulClient.getClient();
        const locales = await client.getLocales();
        const defaultLocale = locales.items.find(locale => locale.default);
        const countryCode = defaultLocale.code.slice(-2);
        const languageCode = defaultLocale.code.substring(0, 2);

        const baseProps = await this.initPage(asPath);
        baseProps.countryCode = countryCode;
        baseProps.languageCode = languageCode;
        return {
            ...baseProps
        }   
    }
             
    static async initPage(slug) {
        let article = undefined;

        // Errors pages doesn't have a page entry in Contentful CMS
        if (slug) {
            const { default: articleDao } = await import('Model/dao/ArticleDao');
            article = await articleDao.getActiveArticleBySlug(slug);
        }

        if(article?.fields?.articleOverview?.fields?.thumbnailImage?.fields){
            article.fields.articleOverview.fields.thumbnailImage.fields.isBannerImage = true
        }

        return {'article' : article};
    }

    constructor(props) {
        super(props);
    }
    /** 
     * Rendering of the page. This always render the page using a common layout.
     *
     * Override the render method to do otherwise.
     *
     * @returns {*}
     */
    render() {
        let article = this.getArticle();
        let pagePath = article?.articleOverview?.fields?.slug?.fields?.slug;
        let metaData = this.getMetadata()
        let faqSchema=this.getFaqSchema()
        let howTo=this.getHowTo()
        if(metaData){
            metaData.pageType = 'article'
        }
        return (
            <Fragment>
                <Meta metadata={metaData} pagePath={pagePath}/>
                <div className="ob-wrapper">
                    {this.renderBody()}
                </div>             
                <ArticlePageSEO
                    metadata={metaData} pagePath={pagePath} article={article}
                />
                {faqSchema && <FaqSectionSEO
                    metadata={metaData} pagePath={pagePath} faqSchema={faqSchema}
                />}
                {howTo && <HowToSEO
                    metadata={metaData} pagePath={pagePath} howTo={howTo}
                />}   
                <GTMScriptFunction languageCode={this.props.languageCode} countryCode={this.props.countryCode} />
            </Fragment>
        )
    }

    getArticle() {
        return this.props?.article?.fields;
    }
    getMetadata() {
        return this.props?.article?.fields?.metadata?.fields;
    }
    getFaqSchema(){
        return this.props?.article?.fields?.faqSchema;
    }
    getHowTo(){
        return this.props?.article?.fields?.howTo;
    }
    /**
     * By default, the page will render the modules of the Product.
     * Can be overridden on the child class to do specific rendering.
     * @returns {*|void}
     */
    renderBody() {
        return new Block(this.props?.article).renderChildBlocks({ extraAttributes : {entity: this.getArticle()}});
    }
}

Article.propTypes = {
    article: PropTypes.object,
    languageCode: PropTypes.string,
    countryCode: PropTypes.string
}

export default Article
